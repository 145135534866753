<template>
  <div>
    <Edit
      :tipos="tiposMaped"
      :colunas="columnsMaped"
      :perfis="perfis"
      :setores="setores"
      :expedientes="expedientes"
      :clientes="clientes"
      :item="currentItem"
      :update="updateItem"
      :store="createItem"
      :remove="deleteConfirm"
      :close="closeEdit"
      :addCliente="addCliente"
      :remCliente="remCliente"
      :parametros="parametros"
    ></Edit>
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="Usuários Ativos">
        <div class="filters row" style="margin-top: 0">
          <div class="col-1-3">
            <span class="filters__search">
              <input
                type="text"
                v-model="filtros.termo"
                placeholder="Buscar por..."
                v-on:input="debounceLoadColaboradores()"
              />
              <img
                style="position: relative; right: -10px"
                src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
              />
            </span>
          </div>

          <div class="col-1-4">
            <select
              class="form-control"
              v-model="filtros.tipoId"
              v-on:change="debounceLoadColaboradores()"
            >
              <option value="">Todos os tipos</option>
              <option v-for="i in tipos" v-bind:value="i.id" v-bind:key="i.id">
                {{ i.desc }}
              </option>
            </select>
          </div>

          <div class="col-1-3">
            <div
              class="col-1-3 d-flex justify-content-center align-items-stretch border rounded border-1"
            >
              <div
                class="d-flex mr-1 justify-content-center align-self-stretch align-items-center"
              >
                <!-- Icone de sort asc e desc, troca ao clicar -->

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="15"
                  style="cursor: pointer"
                  height="15"
                  v-if="filtros.order == 'asc'"
                  v-on:click="
                    filtros.order = 'desc';
                    loadColaboradores();
                  "
                >
                  <path
                    d="M240 96h64a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16h-64a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16zm0 128h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16zm256 192H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h256a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-256-64h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16zm-64 0h-48V48a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v304H16c-14.19 0-21.37 17.24-11.29 27.31l80 96a16 16 0 0 0 22.62 0l80-96C197.35 369.26 190.22 352 176 352z"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="15"
                  height="15"
                  style="cursor: pointer"
                  v-if="filtros.order == 'desc'"
                  v-on:click="
                    filtros.order = 'asc';
                    loadColaboradores();
                  "
                >
                  <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
                  <path
                    d="M304 416h-64a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM16 160h48v304a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V160h48c14.21 0 21.38-17.24 11.31-27.31l-80-96a16 16 0 0 0-22.62 0l-80 96C-5.35 142.74 1.77 160 16 160zm416 0H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-64 128H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM496 32H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h256a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                  />
                </svg>
              </div>

              <select
                class="form-control border-0 px-0"
                v-model="filtros.colunaId"
                v-on:change="loadColaboradores()"
              >
                <option
                  v-for="i in table.columns"
                  v-bind:value="i.column"
                  v-bind:key="i.column"
                >
                  {{ i.label }}
                </option>
              </select>
            </div>
            <div class="pull-right">
              <button
                v-on:click="openCreate"
                class="button button-success button-block"
              >
                <fa-icon icon="plus" />
                Novo {{ descSingular }}
              </button>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <datatable
            :columns="table.columns"
            :data="items"
            :filter-by="filtros.termo"
            class="table table-hover"
          >
            <template slot-scope="{ row }">
              <RowColaborador
                :colaborador="row"
                :deleteConfirm="deleteConfirm"
                :openEdit="openEdit"
              />
            </template>
          </datatable>
        </div>
        <pagination
          :data="table.paginator"
          :limit="2"
          @pagination-change-page="loadColaboradores"
        ></pagination>
      </CTab>
      <CTab title="Aguardando Liberação">
        <div class="table-responsive">
          <datatable
            :columns="table.columns"
            :data="itemsAguardando"
            class="table table-hover"
          >
            <template slot-scope="{ row }">
              <RowColaborador
                :colaborador="row"
                :openEdit="openEdit"
              />
            </template>
          </datatable>
        </div>
        <pagination
          :data="table.paginatorAguardando"
          :limit="2"
          @pagination-change-page="loadColaboradoresAguardando"
        ></pagination>
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import clone from "just-clone";
import _debounce from "lodash/debounce";
import _map from "lodash/map";
import { get, post, put, del } from "../../helpers/apiRequest";
import Edit from "./Edit";
import RowColaborador from "./components/RowColaborador"
import { isSubCliente } from "../../helpers/utils";

function fields(item, key) {
  if (["id", "doc"].indexOf(key) !== -1) {
    return item[key];
  }
  return item[key].toLowerCase();
}

const initialUser = () => ({
  aguardando: 0,
  perfil: null,
  email: null,
  ativo: 1,
  nome: null,
  tipoId: null,
  importaBackups: 1,
  usaContaCorrente: null,
});

export default {
  name: "colaboradores",
  components: {
    Edit,
    RowColaborador
  },
  data() {
    return {
      descPlural: "",
      descSingular: "",
      parametros: [],
      currentTab: 1,
      hasAguardando: false,
      loading: false,
      items: [],
      itemsAguardando: [],
      filtros: {
        termo: "",
        tipoId: "",
        colunaId: "id",
        order: "asc",
      },
      currentItem: {},
      perfis: [],
      setores: [],
      expedientes: [],
      tipos: [],
      tiposInputs: {
        CLIENTE: [],
        ATENDENTE: [
        "visualizaSetor",
        ],
        FUNCIONARIO: [
          "matErp",
          "registraAtendimentoCliente",
          "registraAtendimentoOrcamento",
          "reagendaOrcamento",
          "visualizaSetor",
          "visualizaExpediente",
        ],
        SUPERVISOR: [
          "matErp",
          "codSuperv",
          "codOp",
          "codRca",
          "ramal",
          "assumePed",
          "modificaPolitica",
          "registraAtendimentoCliente",
          "registraAtendimentoOrcamento",
          "reagendaOrcamento",
          "percMaxNegociacao",
          "importaBackups",
          "visualizaSomenteCarteira",
          "visualizaExpediente",
          "visualizaSetor",
          "utilizaSaldoContaCorrenteOutroVendedor",
          "usaRestricaoSegmentoVendaSupervisor",
        ],
        OPERADOR: [
          "matErp",
          "codOp",
          "ramal",
          "assumePed",
          "modificaPolitica",
          "visualizaSomenteCarteira",
          "registraAtendimentoCliente",
          "registraAtendimentoOrcamento",
          "reagendaOrcamento",
          "permiteVendaForaCarteira",
          "visualizaExpediente",
          "visualizaSetor",
          "utilizaSaldoContaCorrenteOutroVendedor",
          "usaRestricaoSegmentoVendaSupervisor",
        ],
        REPRESENTANTE: [
          "codRca",
          "modificaPolitica",
          "registraAtendimentoCliente",
          "registraAtendimentoOrcamento",
          "reagendaOrcamento",
          "orcamentosParados",
          "importaBackups",
          "visualizaExpediente",
          "visualizaSetor",
          "usaContaCorrente",
          "assumePed",
          "permiteNegociar",
          "utilizaSaldoContaCorrenteOutroVendedor",
          "usaRestricaoSegmentoVendaSupervisor",
        ],
        GERENTE: [
          "matErp",
          "codGer",
          "importaBackups",
          "usaRestricaoSegmentoVendaSupervisor",
        ],
        API: ["apiToken"],
      },
      clientes: [],
      table: {
        filter: "",
        columns: [
          { id: 1, label: "ID", field: "id", column: "id", sortable: false },
          {
            id: 2,
            label: "Nome",
            field: "nome",
            column: "nome",
            sortable: false,
          },
          {
            id: 4,
            label: "Tipo",
            field: "tipo",
            column: "tipo_id",
            sortable: false,
          },
          {
            id: 6,
            label: "Perfil",
            field: "perfilDesc",
            column: "perfil_id",
            sortable: false,
          },
          {
            id: 5,
            label: "Criação",
            field: "dtCriacao",
            column: "datacriacao",
            sortable: false,
          },
          {
            label: "Últ. Atualização",
            field: "dtAtualizacao",
            column: "dataatualizacao",
            sortable: false,
          },
          { label: "Ativo", field: "ativo", column: "ativo", sortable: false },
          { label: "Licenciado", field: "licenciado", column: "licenciado", sortable: false },
          { label: "", field: "", column: "", sortable: false },
        ],
        paginator: {},
        paginatorAguardando: {},
      },
    };
  },

  computed: {
    columnsMaped() {
      return this.table.columns.reduce((acc, col) => {
        acc[col.field] = col;
        return acc;
      }, {});
    },
    tiposMaped() {
      return this.tipos.reduce((acc, tipo) => {
        acc[tipo.id] = {
          ...tipo,
          inputs: this.tiposInputs[tipo.chave] || [],
        };
        return acc;
      }, {});
    }
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem("app"));
      const { parametros } = JSON.parse(localStorage.getItem("app"));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === "ADMIN_COLABORADOR"
      );
      this.parametros = parametros;
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadColaboradores(page = 1, take = 15) {
      get(
        `/admin/colaboradores?page=${page}&take=${take}&q=${this.filtros.termo}&tipo=${this.filtros.tipoId}&column=${this.filtros.colunaId}&order=${this.filtros.order}`
      )
        .then((json) => {
          this.items = json.itens;
          this.table.paginator = json.paginator;
          this.hasAguardando =
            json.filter((i) => +i.aguardando === 1).length > 0;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    loadColaboradoresAguardando(page = 1, take = 15) {
      get(
        `/admin/colaboradores/aguardando?page=${page}&take=${take}&q=${this.filtros.termo}`
      )
        .then((json) => {
          this.itemsAguardando = json.itens;
          this.table.paginatorAguardando = json.paginator;
          this.hasAguardando = this.hasAguardando || !!json.itens.length;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    debounceLoadColaboradores: _debounce(function () {
      this.loadColaboradores(), this.loadColaboradoresAguardando();
    }, 300),
    loadTipos() {
      get("/admin/tipos-colaborador")
        .then((json) => {
          this.tipos = json;
        })
        .catch((err) => {});
    },
    loadPerfis() {
      get("/admin/perfis-colaborador")
        .then((json) => {
          this.perfis = json;
        })
        .catch((err) => {});
    },
    changeTab(id) {
      this.currentTab = id;
    },
    openCreate() {
      this.openEdit(initialUser());
    },
    closeCreate() {
      this.$modal.hide("mEdit");
    },
    async openEdit(item) {
      try {
        if (isSubCliente(this.perfis, item.perfil)) {
          this.currentItem = clone(item);

          return this.$modal.show("mEdit");
        }

        const dimensoesRestritas = await this.loadDimensoesRestritas(item.idColab);
        const produtosRestritos = await this.loadProdutosRestritos(item.idColab);

        const colaboradorData = {
          ...item,
          dimensoesRestritas,
          produtosRestritos
        };

        this.currentItem = clone(colaboradorData);

        this.$modal.show("mEdit");
      } catch (error) {
        console.error("Erro ao carregar dimensões restritas:", error);
      }
    },
    closeEdit() {
      this.currentItem = initialUser();
      this.$modal.hide("mEdit");
    },
    createItem(item) {
      this.loading = true;
      const request = post("/admin/colaboradores", item);
      request
        .then((colaboradorCriado) => {
          this.closeEdit();
          this.loadColaboradores();
          this.openEdit(colaboradorCriado);
          this.$swal({
            title: "Sucesso!",
            text: "Usuário cadastrado com sucesso!",
            icon: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
        });
      return request;
    },
    updateItem(liberar, take = 15, data) {
      const { current_page } = this.table.paginator;

      this.loading = true;

      if (liberar) {
        this.currentItem.aguardando = 0;
      }
      
      data.preferencias = { ...data.preferencias };

      const request = put(
        `/admin/colaboradores/${data.id}?page=${current_page}&take=${take}`,
        data
      );

      request
        .then((json) => {
          this.$swal({
            title: "Sucesso!",
            text: "Usuário alterado com sucesso!",
            icon: "success",
          });
          this.loading = false;
          this.loadColaboradores(current_page, take);
          this.loadColaboradoresAguardando();
          this.closeEdit();
        })
        .catch(() => {
          this.loading = false;
        });
      return request;
    },
    deleteConfirm(item) {
      const { id } = item;
      this.$swal({
        title: "Confirma Remover ?",
        text: "Essa ação não poderá ser desfeita",
        showCancelButton: true,
        icon: "warning",
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/colaboradores/${id}`)
              .then((json) => {
                this.loadColaboradores();
                this.loadColaboradoresAguardando();
                this.closeEdit();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: "Falha ao remover",
                  text: err.message,
                  icon: "error",
                });
              });
          }
        })
        .catch(() => {});
    },
    addCliente(colaborador_id, cliente_id) {
      if (!colaborador_id || !cliente_id) {
        return false;
      }
      this.loading = true;
      post(`/admin/colaboradores/${colaborador_id}/clientes/${cliente_id}`)
        .then(() => this.resetColaborador(colaborador_id))
        .catch((err) => {
          this.loading = false;
        });
    },
    remCliente(colaborador_id, cliente_id) {
      if (!colaborador_id || !cliente_id) {
        return false;
      }
      this.$swal({
        title: "Confirma Remover ?",
        showCancelButton: true,
        icon: "error",
        confirmButtonColor: "#d82727",
      }).then((res) => {
        if (res.value) {
          this.loading = true;
          del(`/admin/colaboradores/${colaborador_id}/clientes/${cliente_id}`)
            .then(() => this.resetColaborador(colaborador_id))
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
    resetColaborador(colaborador_id) {
      const { current_page, per_page } = this.table.paginator;
      this.loadColaboradores(current_page, per_page);
      get(`/admin/colaborador/${colaborador_id}`)
        .then((json) => {
          this.currentItem = json;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
    loadSetores() {
      this.loading = true;
      get("/admin/setor-atendimento")
        .then((json) => {
          this.setores = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadExpedientes() {
      this.loading = true;
      get("/admin/lista-expedientes")
        .then((json) => {
          this.expedientes = json;
          this.loading = false;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },

    async loadDimensoesRestritas(colaboradorId) {
      try {
        const response = await get(`/admin/restricoes/dimensoes-restritas/${colaboradorId}`);

        if (Array.isArray(response)) {
          return response.map(dimensao => ({
            coluna_dimensao_bloqueada: dimensao.coluna_dimensao_bloqueada,
            id_dimensao_bloqueada: dimensao.id_dimensao_bloqueada
          }));
        }

        return [];
      } catch (error) {
        console.error('Erro ao carregar dimensões restritas:', error);
        return [];
      }
    },
    async loadProdutosRestritos(colaboradorId) {
      try {
        const response = await get(`/admin/restricoes/mix-restritos/${colaboradorId}`);

        const mixProdutos = response.map((produto) => ({
          value: produto.id,
          label: produto.descComp
        }));

        return mixProdutos;
      } catch (error) {
        console.error('Erro ao carregar dimensões restritas:', error);
      }
    }
  },
  beforeMount() {
    this.loading = true;
    this.loadColaboradores();
    this.loadColaboradoresAguardando();
    this.loadPerfis();
    this.loadTipos();
    this.loadDescricaoModulo();
    this.loadSetores();
    this.loadExpedientes();
  },
};
</script>
